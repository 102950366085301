<script context="module" lang="ts">
  import { _ } from "svelte-i18n";
  import { init } from "$i18n/i18n";
  import Dropdown from "$utils/Dropdown.svelte";
  import RotatableArrow from "$utils/RotatableArrow.svelte";
  import UserMenu from "./UserMenu.svelte";
  import { userLoaded, userSession } from "$api/user";
  await init();
</script>

<script lang="ts">
  let modalOpen = false;
  let username = "";

  $: if ($userLoaded)
    userSession.getUser().subscribe((user) => (username = user.name));
</script>

<div
  class="flex w-full h-[8vh] min-h-[8vh] relative border-b-2 p-2 items-center justify-between"
>
  <Dropdown
    dropdownClasses="z-[1] menu p-2 shadow bg-white text-base-content rounded-box"
    bind:modalOpen
  >
    <label
      slot="outside"
      class="flex gap-2 cursor-pointer p-1 rounded-lg items-center"
    >
      <img
        src="/static/branding/PrintLabels online_logo.svg"
        alt="Menu"
        class="h-[4vh]"
      />
      <RotatableArrow
        rotated={modalOpen}
        trueState="270deg"
        falseState="90deg"
        iconSize="0.7rem"
      />
    </label>

    <li>
      <button
        class="text-clip whitespace-nowrap"
        on:click={() => aboutUs.showModal()}>{$_("nav.aboutus")}</button
      >
    </li>
  </Dropdown>

  <div class="flex gap-12 text-1xl items-center pr-2 h-full">
    <img
      src="/static/branding/Altec-logo-kleur.svg"
      class="h-[4vh]"
      alt="altec logo"
    />
    <UserMenu {username} />
  </div>
</div>
